module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"report","path":"/preview","previews":true,"pages":[{"type":"Story","match":"/:uid","path":"/","component":"/opt/build/repo/src/templates/story.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-56009941-1","head":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"See Me Report","short_name":"See Me Report","start_url":"/","icon":"src/images/icon.png"},
    }]
